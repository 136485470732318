<template>
  <div>
    <b-form-group>
      <div class="tag-input" v-if="!examQuestions[questionIndex].disabled">
        <div v-for="(choice, choiceIndex) in examQuestions[questionIndex].choices" :key="choiceIndex"
          class="tag-input__tag ">
          <span class="bg-info p-1 rounded text-white">
            {{ choice.name }}
            <i @click="removeChoice(choiceIndex)" class="fas fa-times-circle text-white btn p-0 m-0"></i></span>
        </div>
        <input type="text" placeholder="Enter a choice" class="tag-input__text d-flex align-items-center rounded"
          :class="{
            'form-group-error':
              v.examQuestions.$each[questionIndex].choices.$error,
          }" v-model="choice" :disabled="examQuestions[questionIndex].disabled"
          @keydown.enter="addChoice(questionIndex)" @keypress.enter.prevent />
      </div>

      <span class="text-info">Enter a choice and press 'enter' key to add choice</span>
    </b-form-group>
    <b-form-group v-if="
      exam.is_objective_only == 1 &&
      exam.maximum_mark != '' &&
      examQuestions[questionIndex].choices.length != 0
    ">
      <b-form-radio v-for="(choice, choiceIndex) in examQuestions[questionIndex].choices" :key="choiceIndex"
        :value="choiceIndex" v-model="examQuestions[questionIndex].correctChoiceIndex" :class="{
          'form-group-error':
            v.examQuestions.$each[questionIndex].correctChoiceIndex.$error,
        }">{{ choice.name }}</b-form-radio>

    </b-form-group>
    <span class="input-error-message" v-if="
  !v.examQuestions.$each[questionIndex].correctChoiceIndex.required &&
  v.examQuestions.$each[questionIndex].correctChoiceIndex.$dirty
    ">Please select the correct choice!/Correct Choice not found!</span>
  </div>
</template>

<script>
export default {
  props: ["exam", "questionIndex", "examQuestions", "v"],

  data() {
    return {
      choice: "",
    };
  },
  methods: {
    removeChoice(choiceIndex) {
      this.examQuestions[this.questionIndex].choices.splice(choiceIndex, 1);
      this.examQuestions[this.questionIndex].correctChoiceIndex = "";
    },
    //********** Create Exam Choices **********/
    async addExamChoices(choices, questionId) {
      let status = null;
      const url =
        this.$store.getters.getAPIKey.examQuestions +
        `/${questionId}/examchoices/storemany`;
      await this.$axios
        .post(url, { examChoices: choices })
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    addChoice(questionIndex) {
      if (this.choice.length > 0) {
        this.examQuestions[questionIndex].choices.push({
          id: "",
          name: this.choice,
        });
        this.choice = "";
      }
    },
  },
};
</script>

<style >
.tag-input i {
  font-size: 14px !important;
}
</style>
