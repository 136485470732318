var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',[(!_vm.examQuestions[_vm.questionIndex].disabled)?_c('div',{staticClass:"tag-input"},[_vm._l((_vm.examQuestions[_vm.questionIndex].choices),function(choice,choiceIndex){return _c('div',{key:choiceIndex,staticClass:"tag-input__tag "},[_c('span',{staticClass:"bg-info p-1 rounded text-white"},[_vm._v(" "+_vm._s(choice.name)+" "),_c('i',{staticClass:"fas fa-times-circle text-white btn p-0 m-0",on:{"click":function($event){return _vm.removeChoice(choiceIndex)}}})])])}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.choice),expression:"choice"}],staticClass:"tag-input__text d-flex align-items-center rounded",class:{
          'form-group-error':
            _vm.v.examQuestions.$each[_vm.questionIndex].choices.$error,
        },attrs:{"type":"text","placeholder":"Enter a choice","disabled":_vm.examQuestions[_vm.questionIndex].disabled},domProps:{"value":(_vm.choice)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addChoice(_vm.questionIndex)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.choice=$event.target.value}}})],2):_vm._e(),_c('span',{staticClass:"text-info"},[_vm._v("Enter a choice and press 'enter' key to add choice")])]),(
    _vm.exam.is_objective_only == 1 &&
    _vm.exam.maximum_mark != '' &&
    _vm.examQuestions[_vm.questionIndex].choices.length != 0
  )?_c('b-form-group',_vm._l((_vm.examQuestions[_vm.questionIndex].choices),function(choice,choiceIndex){return _c('b-form-radio',{key:choiceIndex,class:{
        'form-group-error':
          _vm.v.examQuestions.$each[_vm.questionIndex].correctChoiceIndex.$error,
      },attrs:{"value":choiceIndex},model:{value:(_vm.examQuestions[_vm.questionIndex].correctChoiceIndex),callback:function ($$v) {_vm.$set(_vm.examQuestions[_vm.questionIndex], "correctChoiceIndex", $$v)},expression:"examQuestions[questionIndex].correctChoiceIndex"}},[_vm._v(_vm._s(choice.name))])}),1):_vm._e(),(
!_vm.v.examQuestions.$each[_vm.questionIndex].correctChoiceIndex.required &&
_vm.v.examQuestions.$each[_vm.questionIndex].correctChoiceIndex.$dirty
  )?_c('span',{staticClass:"input-error-message"},[_vm._v("Please select the correct choice!/Correct Choice not found!")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }