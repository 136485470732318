<template>
  <div>
    <br />
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="examQuestions" v-else>
      <b-container fluid>
        <br />
        <div class="closeBtn mb-2 text-right">
          <p @click="closeCurrentTab()" class="btn m-0">
            <i class="fas fa-window-close fa-4x text-danger"></i>
          </p>
        </div>
        <h3 class="font-weight-bolder text-center">
          Questions: <span class="text-info">{{ exam.name }}</span>
        </h3>

        <b-button variant="white" class="text-primary" v-if="examQuestions.length == 0"
          @click="examQuestionsForm = true && prepareExamQuestions()"><i
            class="fas fa-plus-square fa-2x"></i></b-button>
        <br />
        <b-form v-if="examQuestions.length != 0" @submit.enter.prevent="prepareExamQuestionForApi()">
          <div class="examQuestion border border-info p-3 mt-2 rounded bg-white"
            v-for="(question, questionIndex) in examQuestions" :key="questionIndex">
            <div class="createdAt mb-1 font-italic font-weight-bolder text-secondary"
              v-if="question.created_at != null">
              Created Date:
              {{
                  question.created_at.format("DD/MM/YYYY") +
                  " " +
                  question.created_at.format("hh:mm a")
              }}
            </div>
            <div class="questionView" v-if="question.disabled">
              <div class="questionNoAndEdit d-flex">
                <p style="font-size: 16px; font-weight: 600">
                  {{ question.question_no }}:
                </p>
                <p class="ml-2" style="font-size: 16px; font-weight: 600">
                  {{ question.question }}
                </p>

                <div class="ml-auto" v-if="question.questionId != ''">
                  <b-button variant="info" style="font-size: 12px" size="sm" v-if="question.disabled == true"
                    @click="editExamQuestion(questionIndex)">Edit</b-button>
                </div>
              </div>
              <div class="choices mt-2 d-flex" v-if="question.existingChoices.length != 0">
                <span v-for="({ name, is_right_choice },
                choiceIndex) in question.existingChoices" :key="choiceIndex"
                  :class="is_right_choice == 1 ? 'bg-success' : 'bg-danger'"
                  class="text-white ml-2 p-1 rounded d-flex align-items-center">
                  {{ name }}
                </span>
              </div>

              <div class="attachments">
                <div v-if="question.files && question.files.length != 0" class="d-flex flex-column text-left">
                  <span class="text-dark mt-3" v-for="(file, fileIndex) in question.files" :key="fileIndex">
                    <a :href="userAttachments + file.file_path + file.file_name"
                      style="font-size: 12px; font-weight: 900" class="text-decoration-none" target="_blank">
                      {{ file.file_name }}
                    </a>
                    <span v-if="!question.disabled" class="btn p-0 m-0 ml-2 text-danger" @click="
                      deleteAttachment(questionIndex, fileIndex, file.id)
                    "><i class="fas fa-trash"></i></span>
                  </span>
                </div>
              </div>
              <div class="questionOptions d-flex justify-content-center" v-if="exam.is_objective_only == 0">
                <div :title="
                  exam.is_objective_only == 1 ? 'Only Objectives allowed' : ''
                ">
                  <b-form-checkbox v-model.trim="question.allow_descriptive" disabled><span :class="
                    question.allow_descriptive
                      ? 'text-success'
                      : 'text-danger'
                  " style="font-size: 12px; font-weight: 600">Allow Text Field</span></b-form-checkbox>
                </div>

                <b-form-checkbox v-model.trim="question.allow_attachment_answer" disabled class="ml-3"><span
                    style="font-size: 12px; font-weight: 600" :class="
                      question.allow_attachment_answer
                        ? 'text-success'
                        : 'text-danger'
                    ">Allow Attachment</span></b-form-checkbox>
              </div>
            </div>
            <div class="questionEditView" v-if="!question.disabled">
              <b-overlay :show="loadQuestion" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
                <b-form-group>
                  <div class="questionNoAndEdit">
                    <div class="d-flex justify-content-between">
                      <b-form-input v-model="question.question_no" style="width: 100px" placeholder="Q No." :class="{
                        'form-group-error':
                          $v.examQuestions.$each[questionIndex].question_no
                            .$error,
                      }" :disabled="question.disabled"></b-form-input>
                      <b-form-input v-if="(!exam.positive_mark && exam.offline_activity == 0)"
                        v-model="question.maximum_mark" style="width: 100px" placeholder="Score for right answer"
                        :class="{
                          'form-group-error':
                            $v.examQuestions.$each[questionIndex].maximum_mark
                              .$error,
                        }" :disabled="question.disabled"></b-form-input>
                      <div class="deleteQuestion" v-if="question.questionId == ''">
                        <span class="btn p-0 m-0 text-danger" @click="examQuestions.splice(questionIndex, 1)"><i
                            class="fas fa-minus-circle"></i></span>
                      </div>
                      <div class="ml-auto" v-if="question.questionId != ''">
                        <b-button variant="info" style="font-size: 12px" size="sm" v-if="question.disabled == true"
                          @click="editExamQuestion(questionIndex)">Edit</b-button>
                        <b-button variant="info" style="font-size: 12px" size="sm" v-if="question.disabled == false"
                          @click="
                            prepareExamQuestionsForUpdating(questionIndex)
                          ">Save</b-button>
                      </div>
                    </div>
                    <span class="input-error-message" v-if="
                      !$v.examQuestions.$each[questionIndex].question_no
                        .required &&
                      $v.examQuestions.$each[questionIndex].question_no
                        .$dirty
                    ">Question Number is mandatory!</span>
                  </div>
                </b-form-group>
                <b-form-group>
                  <div class="question">
                    <b-form-input v-model="question.question" placeholder="Enter Question...." :class="{
                      'form-group-error':
                        $v.examQuestions.$each[questionIndex].question.$error,
                    }" :disabled="question.disabled"></b-form-input>
                    <span class="input-error-message" v-if="
                      !$v.examQuestions.$each[questionIndex].question
                        .required &&
                      $v.examQuestions.$each[questionIndex].question.$dirty
                    ">Question is mandatory!</span>
                  </div>
                </b-form-group>
                <b-form-group v-if="question.existingChoices.length != 0">
                  <div class="choices mt-2 d-flex">
                    <span v-for="({ id, name, is_right_choice },
                    choiceIndex) in question.existingChoices" :key="choiceIndex"
                      :class="is_right_choice == 1 ? 'bg-success' : 'bg-danger'"
                      class="text-white ml-2 p-1 rounded d-flex align-items-center">
                      {{ name }}
                      <span class="ml-1 btn p-0 m-0 text-white">
                        <i class="fas fa-trash" @click="
                          deleteExamChoice(id, questionIndex, choiceIndex)
                        "></i>
                      </span>
                    </span>
                  </div>
                </b-form-group>
                <b-form-group v-if="exam.is_objective_only == 1">
                  <b-button v-if="question.questionId != ''" size="sm" v-b-toggle.addChoices>Add more choices</b-button>
                  <b-collapse :visible="question.questionId == ''" id="addChoices">
                    <ExamChoices :ref="'examChoices' + questionIndex" :exam="exam" :questionIndex="questionIndex"
                      :examQuestions="examQuestions" :v="$v" />
                    <span class="input-error-message" v-if="
                      !$v.examQuestions.$each[questionIndex].choices.required &&
                      $v.examQuestions.$each[questionIndex].choices.$dirty
                    ">Minimum 2 choices are required!</span>
                  </b-collapse>
                </b-form-group>
                <b-form-group class="text-right">
                  <b-form-file multiple :ref="'attachQuestions' + questionIndex" style="display: none"
                    class="text-center" v-model="question.attachments"></b-form-file>
                  <div>
                    <span class="btn text-info p-0 m-0" @click="attachQuestions(questionIndex)"
                      v-if="!question.disabled"><i class="fas fa-paperclip fa-2x"></i></span>
                    <div class="d-flex flex-column ">
                      <span class="text-dark " v-for="(file, attaachmentIndex) in question.attachments"
                        :key="attaachmentIndex">
                        <span style="font-size: 12px; font-weight: 900;">
                          {{ file.name }}
                          <span class="btn p-0 m-0 ml-2 text-danger"
                            @click="question.attachments.splice(attaachmentIndex, 1)">
                            <i class="fas fa-trash"></i>
                          </span>
                        </span>
                      </span>

                      <div v-if="question.files && question.files.length != 0" class="d-flex flex-column text-left ">
                        <span class="text-dark mt-3" v-for="(file, fileIndex) in question.files" :key="fileIndex">
                          <a :href="
                            userAttachments + file.file_path + file.file_name
                          " style="font-size: 12px; font-weight: 900;" class="text-decoration-none" target="_blank">
                            {{ file.file_name }}
                          </a>
                          <span v-if="!question.disabled" class="btn p-0 m-0 ml-2 text-danger" @click="
                            deleteAttachment(
                              questionIndex,
                              fileIndex,
                              file.id
                            )
                          "><i class="fas fa-trash"></i></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group v-if="exam.is_objective_only == 0">
                  <div class="questionOptions d-flex justify-content-center">
                    <div :title="
                      exam.is_objective_only == 1
                        ? 'Only Objectives allowed'
                        : ''
                    ">
                      <b-form-checkbox v-model.trim="question.allow_descriptive" :disabled="
                        question.disabled || !question.allow_attachment_answer
                      ">Allow Text Field</b-form-checkbox>
                    </div>

                    <b-form-checkbox v-model.trim="question.allow_attachment_answer" :disabled="
                      question.disabled || !question.allow_descriptive
                    " class="ml-3">Allow Attachment</b-form-checkbox>
                  </div>
                </b-form-group>
              </b-overlay>
            </div>
          </div>
          <b-form-group class="mt-3">
            <b-button variant="primary" type="submit" size="md" :disabled="submitBtn.disabled"
              @click="addQues = true">{{ submitBtn.addQues }}</b-button>
          </b-form-group>
          <b-form-group class="w-100 text-center">
            <b-button variant="primary" type="submit" size="md" :disabled="submitBtn.disabled"
              @click="addQues = false">{{ submitBtn.text }}</b-button>
          </b-form-group>
          <br />
        </b-form>
      </b-container>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
import ExamChoices from "./ExamChoices";
export default {
  async created() {
    this.fetchData = true;
    await this.getExam();
    await this.getExamQuestions();
    this.userAttachments = this.$store.getters.getAPIKey.userAttachments;
    this.fetchData = false;
  },
  components: {
    ExamChoices,
  },
  data() {
    return {
      loadQuestion: false,
      userAttachments: null,
      fetchData: false,
      addQues: false,
      exam: {},
      examQuestions: [],
      examQuestionsForm: false,
      submitBtn: {
        text: "Submit",
        addQues: "Add Question",
        disabled: false,
      },
    };
  },
  validations: {
    examQuestions: {
      required,
      $each: {
        question_no: {
          required,
        },
        question: {
          required,
        },
        choices: {
          required: requiredIf(function (choice) {
            if (
              (this.exam.is_objective_only == 1 &&
                this.exam.is_objective_only == 1 &&
                choice.choices.length < 2 &&
                choice.existingChoices.length == 0) ||
              (this.exam.is_objective_only == 1 &&
                choice.choices.length == 0 &&
                choice.existingChoices.length < 2)
            ) {
              return true;
            }
          }),
        },
        correctChoiceIndex: {
          required: requiredIf(function () {
            if (this.exam.is_objective_only == 1) {
              return true;
            }
          }),
        },
        maximum_mark: {
          required: requiredIf(function () {
            if (this.exam.positive_mark == null && this.exam.offline_activity == 0) {
              return true;
            }
          }),
        },
      },
    },
  },
  methods: {
    //******** Prepare Exam Question Post DATA *********/
    async prepareExamQuestionForApi() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        await this.addQues;
        if (
          this.examQuestions[this.examQuestions.length - 1].questionId != "" &&
          !this.addQues
        ) {
          return this.closeCurrentTab();
        }
        if (
          this.examQuestions[this.examQuestions.length - 1].questionId != "" &&
          this.addQues
        ) {
          return this.addExamQuestions();
        }
        this.submitBtn.text = "Please wait...";
        this.submitBtn.addQues = "Please wait...";
        this.submitBtn.disabled = true;
        this.loadQuestion = true;
        await this.addQues;
        const ques = this.examQuestions[this.examQuestions.length - 1];
        const examQues = await {
          question_no: ques.question_no,
          maximum_mark: ques.maximum_mark,
          name: ques.question,
          allow_descriptive: ques.allow_descriptive,
          allow_attachment_answer: ques.allow_attachment_answer,
          answer_required: ques.answer_required,
          // parent_id: "",
          // parent_type: "",
          exam_id: `${ques.exam_id}`,
        };

        let createQues = await this.createExamQuestion(examQues);
        if (createQues.status) {
          await this.$toast.success("Question Created!", {
            position: "top",
            duration: 3000,
          });
          if (
            this.examQuestions[this.examQuestions.length - 1].choices.length !=
            0 &&
            this.exam.is_objective_only == 1
          ) {
            const choicesPostData = [];
            await this.examQuestions[
              this.examQuestions.length - 1
            ].choices.forEach((choice, choiceIndex) => {
              choicesPostData.push({
                name: choice.name,
                is_right_choice:
                  this.examQuestions[this.examQuestions.length - 1]
                    .correctChoiceIndex == choiceIndex
                    ? true
                    : false,
                question_id: createQues.questionId,
              });
            });
            const index = this.examQuestions.length - 1;
            const choices = await this.$refs["examChoices" + index][0];
            const addExamChoices = await choices.addExamChoices(
              choicesPostData,
              createQues.questionId
            );
            if (addExamChoices) {
              await this.$toast.success("Choices Attached!", {
                position: "top",
                duration: 3000,
              });
            } else {
              await this.$toast.error(
                "Choices Failed to attcah, Please try again",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            }
          }

          if (
            this.examQuestions[this.examQuestions.length - 1].attachments
              .length != 0
          ) {
            for (let attachmentIndex in this.examQuestions[
              this.examQuestions.length - 1
            ].attachments) {
              const addAttachments = await this.addAttachment(
                this.examQuestions[this.examQuestions.length - 1].attachments[
                attachmentIndex
                ],
                createQues.questionId
              );
              if (
                addAttachments &&
                attachmentIndex ==
                this.examQuestions[this.examQuestions.length - 1].attachments
                  .length -
                1
              ) {
                await this.$toast.success("Attachments Added.", {
                  position: "top",
                  duration: 3000,
                });
              }
              if (!addAttachments) {
                await this.$toast.error(
                  "Something went wrong with the attachments.",
                  {
                    position: "top",
                    duration: 3000,
                  }
                );
              }
            }
          }

          if (this.addQues) {
            await this.getExamQuestions();
          }

          await this.clearResponse(true);
        }
        if (!createQues.status) {
          await this.$toast.error("Something went wrong!", {
            position: "top",
            duration: 3000,
          });
          this.clearResponse(false);
        }
      }
    },
    //********* Create Exam Question  **********/
    async createExamQuestion(examQues) {
      const url = this.$store.getters.getAPIKey.examQuestions;
      let questionStatus = {
        status: null,
        questionId: "",
      };
      await this.$axios
        .post(url, examQues)
        .then((response) => {
          questionStatus.status = true;
          questionStatus.questionId = response.data.id;
          response;
        })
        .catch((error) => {
          questionStatus.status = false;
          questionStatus.questionId = "";
          error;
        });
      return questionStatus;
    },
    //*************** Add Attachments **************/
    async addAttachment(attchmentData, examQuestionId) {
      let status = null;
      const fd = await new FormData();
      await fd.append("attachment", attchmentData);
      const url =
        this.$store.getters.getAPIKey.examQuestions +
        `/${examQuestionId}/attachments`;
      await this.$axios
        .post(url, fd)
        .then((response) => {
          status = true;

          response;
        })
        .catch((error) => {
          error;
          status = false;
        });
      return await status;
    },
    async deleteAttachment(questionIndex, fileIndex, fileId) {
      this.loadQuestion = true;

      const url =
        this.$store.getters.getAPIKey.examQuestions + `/attachments/${fileId}`;
      await this.$axios
        .delete(url)
        .then((response) => {
          this.examQuestions[questionIndex].files.splice(fileIndex, 1);
          this.$toast.success("Attachment Deleted", {
            position: "top",
            duration: 3000,
          });
          this.loadQuestion = false;
          response;
        })
        .catch((error) => {
          this.loadQuestion = false;
          error;
        });
    },
    //********* Prepare Question Data for Update **********/
    async prepareExamQuestionsForUpdating(questionIndex) {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        const ques = await this.examQuestions[questionIndex];
        this.loadQuestion = true;

        let examQues = await {
          question_no: ques.question_no,
          maximum_mark: ques.maximum_mark,
          name: ques.question,
          allow_descriptive: ques.allow_descriptive,
          allow_attachment_answer: ques.allow_attachment_answer,
          answer_required: ques.answer_required,
          // parent_id: "",
          // parent_type: "",
          exam_id: `${ques.exam_id}`,
        };
        const questionId = ques.questionId;
        const questionUpdated = await this.updateExamQuestion(
          examQues,
          questionId
        );
        if (questionUpdated) {
          this.$toast.success("Question Updated", {
            position: "top",
            duration: 3000,
          });
          if (
            this.examQuestions[questionIndex].choices.length != 0 &&
            this.exam.is_objective_only == 1
          ) {
            const choicesPostData = [];
            await this.examQuestions[questionIndex].choices.forEach(
              (choice, choiceIndex) => {
                choicesPostData.push({
                  name: choice.name,
                  is_right_choice:
                    this.examQuestions[questionIndex].correctChoiceIndex ==
                      choiceIndex
                      ? true
                      : false,
                  question_id: this.examQuestions[questionIndex].questionId,
                });
              }
            );
            const index = questionIndex;
            const choices = await this.$refs["examChoices" + index][0];
            const addExamChoices = await choices.addExamChoices(
              choicesPostData,
              this.examQuestions[questionIndex].questionId
            );
            if (addExamChoices) {
              await this.$toast.success("Choices Attached!", {
                position: "top",
                duration: 3000,
              });
            } else {
              await this.$toast.error(
                "Choices Failed to attcah, Please try again",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            }
          }
          if (this.examQuestions[questionIndex].attachments.length != 0) {
            for (let attachmentIndex in this.examQuestions[questionIndex]
              .attachments) {
              const addAttachments = await this.addAttachment(
                this.examQuestions[questionIndex].attachments[attachmentIndex],
                this.examQuestions[questionIndex].questionId
              );
              if (
                addAttachments &&
                attachmentIndex ==
                this.examQuestions[questionIndex].attachments.length - 1
              ) {
                await this.$toast.success("Attachments Updated.", {
                  position: "top",
                  duration: 3000,
                });
              }
              if (!addAttachments) {
                await this.$toast.error(
                  "Something went wrong with the attachments.",
                  {
                    position: "top",
                    duration: 3000,
                  }
                );
              }
            }
          }
          await this.getExamQuestions();
          this.clearResponse();
        }
        if (!questionUpdated) {
          this.$toast.error("Something Went Wrong, please try again!", {
            position: "top",
            duration: 3000,
          });

          this.clearResponse();
        }
      }
    },
    //********* Update Survey Question API **********/
    async updateExamQuestion(examQues, questionId) {
      const url =
        this.$store.getters.getAPIKey.examQuestions + `/${questionId}`;
      let status = null;
      await this.$axios
        .put(url, examQues)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    async getExamQuestions() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.examination +
        `/${this.exam.id}/examquestions`;

      await this.$axios
        .get(url)
        .then((response) => {
          this.examQuestions = [];
          const questions = response.data;
          questions.forEach((ques) => {
            let choiceIndex = ques.choices.findIndex((choice) => {
              return choice.is_right_choice == 1;
            });

            this.examQuestions.push({
              questionId: ques.id,
              question_no: ques.question_no,
              question: ques.name,
              choices: [],
              existingChoices: ques.choices,
              correctChoiceIndex: choiceIndex != -1 ? choiceIndex : "",
              files: ques.files,
              attachments: [],
              allow_descriptive: ques.allow_descriptive == 1 ? true : false,
              allow_attachment_answer:
                ques.allow_attachment_answer == 1 ? true : false,
              answer_required: ques.answer_required == 1 ? true : false,
              maximum_mark: ques.maximum_mark,
              // parent_id: "",
              // parent_type: "",
              section_id: "",
              exam_id: ques.exam_id,
              disabled: true,
              created_at: moment(ques.created_at),
            });
          });

          response;
        })
        .catch((error) => {
          error;
        });
      this.fetchData = false;
    },
    async deleteExamChoice(choiceId, questionIndex, choiceIndex) {
      this.loadQuestion = true;
      if (
        this.examQuestions[questionIndex].existingChoices[choiceIndex]
          .is_right_choice == 1
      ) {
        this.examQuestions[questionIndex].correctChoiceIndex = "";
      }
      const url = this.$store.getters.getAPIKey.examChoices + `/${choiceId}`;
      await this.$axios
        .delete(url)
        .then((response) => {
          this.$toast.success("Choice Deleted!", {
            position: "top",
            duration: 3000,
          });
          this.examQuestions[questionIndex].existingChoices.splice(
            choiceIndex,
            1
          );
          this.loadQuestion = false;
          response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong!", {
            position: "top",
            duration: 3000,
          });
          this.loadQuestion = false;
          error;
        });
    },
    clearResponse(status) {
      this.submitBtn.text = "Submit";
      this.submitBtn.addQues = "Add Question";
      this.loadQuestion = false;
      if (this.addQues && status) {
        this.addExamQuestions();
      }
      setTimeout(() => {
        if (!this.addQues && status) {
          this.closeCurrentTab();
        }
      }, 3000);
      this.submitBtn.disabled = false;
    },
    editExamQuestion(questionIndex) {
      this.submitBtn.disabled = true;
      const question = this.examQuestions[questionIndex];
      this.examQuestions.forEach((question) => {
        if (question.questionId != "") {
          question.disabled = true;
        }
      });
      question.disabled = false;
      this.$forceUpdate();
    },
    // selectCorrectChoice(questionIndex, choiceIndex) {},
    prepareExamQuestions() {
      this.examQuestions.push({
        questionId: "",
        question_no: "",
        question: "",
        choices: [],
        existingChoices: [],
        correctChoiceIndex: "",
        files: [],
        attachments: [],
        allow_descriptive: this.exam.is_objective_only == 0 ? true : false,
        allow_attachment_answer:
          this.exam.is_objective_only == 0 ? true : false,
        answer_required: true,
        maximum_mark: "",
        // parent_id: "",
        // parent_type: "",
        section_id: "",
        exam_id: this.exam.id ? this.exam.id : "",
        disabled: false,
      });
    },
    addExamQuestions() {
      this.examQuestions.push({
        questionId: "",
        question_no: "",
        question: "",
        choices: [],
        existingChoices: [],
        correctChoiceIndex: "",
        files: [],
        attachments: [],
        allow_descriptive: this.exam.is_objective_only == 0 ? true : false,
        allow_attachment_answer:
          this.exam.is_objective_only == 0 ? true : false,
        answer_required: true,
        maximum_mark: "",
        // parent_id: "",
        // parent_type: "",
        section_id: "",
        exam_id: this.exam.id ? this.exam.id : "",
        disabled: false,
        created_at: null,
      });
    },
    attachQuestions(question) {
      let attachedQuestion = `${"attachQuestions" + question}`;
      this.$refs[attachedQuestion][0].$refs.input.click();
    },
    async getExam() {
      const examId = this.$route.params.exam_id;
      const url = this.$store.getters.getAPIKey.examination + `/${examId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.exam = {};
          this.exam = response.data;

          response;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.examQuestions .choices i {
  font-size: 10px !important;
}

.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9em;
  overflow: hidden;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
}

.tag-input__tag {
  height: 30px;
  float: left;
  margin-right: 2px;
  overflow: hidden;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px;
  border-radius: 5px;
}

.tag-input__tag>span {
  opacity: 0.75;
}

.tag-input__text {
  border: none;
  outline: none;
  overflow: hidden;
  font-size: 0.9em;
  line-height: 50px;
  background: none;
}
</style>
